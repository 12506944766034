<template>

  <v-sheet elevation="0"
           v-if="loader.detail"
           :color="`grey darken-2 `"
           class="pa-0"
  >
    <v-skeleton-loader
      class="mx-auto"
      width="100%"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-img height="300" class=" align-end"
           :src="comicbook.cover_src"
           cover
           gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,.9)"
    >
      <div class="d-flex flex-column fill-height  text-white">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="warning" v-if="user_id && user_id == comicbook.user_id" small
                 :to="'/comicbook/form/'+comicbook.id">
            <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            Editer
          </v-btn>
        </v-card-title>
        <v-card-subtitle color="deep-purple darken-3" class="mb-0 pb-0">
          <v-chip small color="whitesmook"
                  v-for="gender in comicbook.genders"
                  :key="gender.id"
          >
            {{ gender.name['fr'] }}
          </v-chip>
        </v-card-subtitle>
        <v-card-text class="py-0">
          <h1>
            {{ comicbook.name }}
          </h1>
        </v-card-text>
        <!--        <v-card-subtitle color="deep-purple darken-3" class="mb-0 pb-0">
                  ({{ comicbook.alternatif }})
                </v-card-subtitle>-->

        <v-card-text class="py-0">
          <template v-for="author in comicbook.authors">
            <b>{{ author.pseudo }}</b>
          </template>

          <template v-if="comicbook.user_id">
            <div
              :to="'/profile/'+comicbook.user_id+''" color="none"
              class="d-flex flex-no-wrap justify-start">
              <v-avatar
                size="30"
                class="ma-1 av-active"
              >
                <v-img
                  v-if="comicbook.user.cover"
                  alt="Avatar"
                  contain
                  :src="comicbook.user.cover"
                  :lazy-src="require(`@/assets/images/avatars/logo.png`)"
                />
                <v-img
                  v-else
                  alt="Avatar"
                  contain
                  :src="require(`@/assets/images/avatars/logo.png`)"
                />
              </v-avatar>
              <v-card-title class="title-inline ma-1 pa-0 text-center">
                <b>{{ comicbook.user.username }}</b>
              </v-card-title>
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="text-center py-0">
                  <span class="text-center">
                  <v-rating readonly
                            :value="parseFloat(comicbook.rate)"
                            hover
                            :size="21" dense
                            half-increments density="compact"
                            active-color="primary"
                  />
                 <small>{{ comicbook.rate }}/5 | {{ comicbook.nbvote }} Votes</small>

                  </span>
          <v-spacer></v-spacer>
          <p>{{ comicbook.nbsubscription }}<br>
            <template v-if="comicbook.id">
              <comicbook-follow-toggle-component
                :status="comicbook.subscribed"
                :user_id="parseInt(user_id)"
                :comicbook_id="parseInt(comicbook.id)"
                @initlogin="()=>$store.commit('updateAuthentication', true)"
                @subscribed="onSubscribed"
              ></comicbook-follow-toggle-component>
            </template>
          </p>
        </v-card-actions>
      </div>
    </v-img>
    <!--    <v-card-title color="deep-purple darken-3">Synopsis</v-card-title>
        <v-card-text>
          {{ comicbook.synopsis }}
        </v-card-text>-->

    <v-row dense >
      <v-col cols="12" md="7">
<!--        <v-expansion-panels>
          <v-expansion-panel
            key="contest"
          >
            <v-expansion-panel-header></v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="comicbook.synopsis"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>-->
        <v-card-text>
          <h3>Synopsis ...</h3>
          <read-more
            :content="comicbook.synopsis" :max-length="120" ></read-more>
        </v-card-text>
      </v-col>
      <v-col cols="12" md="5">
        <v-card-text class="pb-3" v-if="comicbook.id" >
          <comicbook-statistic-component
            :user_id="user_id"
            :comicbook="comicbook"
          ></comicbook-statistic-component>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row dense >
      <v-col cols="12" md="7">
        <v-card-title color="deep-purple darken-3" class="  pb-2">
          3 derniers Chapitres
        </v-card-title>
        <v-card-text>
          <v-list dense class="pa-0">
            <template v-for="(item, index) in chapters">
              <!--              <v-divider
                              :key="index"
                            ></v-divider>-->

              <v-list-item
                :key="item.title" class="pa-0"
                :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                dense
              >
                <img
                  class="mr-3"
                  width="50"
                  :src="item.cover_src_sm"
                >

                <v-list-item-content>
                  <v-list-item-title class="title-inline">
                    <b>#{{ item.number }}</b> {{ item.title }}
                    <v-spacer></v-spacer>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.creationdate }} -
                    <v-icon small>
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                    {{ item.nbviews }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                </v-list-item-icon>
                <v-list-item-action>
                  <span
                    v-if="item.product.price == 0"
                    class="text--primary2  font-secondary"
                  >
                    Free
                  </span>
                  <template v-if="item.product.price > 0">
                    <v-chip
                      small
                      color="warning"
                    >
                      {{ item.product.price }} Credits
                    </v-chip>
                    Extrait {{ item.free_pages }} pages
                  </template>
                  <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        <v-tabs class=" position-sticky font-secondary"
                v-model="tab" center-active
                align-tabs="center"
                grow
        >
          <v-tab
            key="illustrations"
          >
            Chapitres ({{ comicbook.nbchapter }})
          </v-tab>
          <v-tab
            key="series"
          >
            MiniChap ({{ comicbook.nbminichapter }})
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Actualites
          </v-tab>
        </v-tabs>

        <template>

          <v-tabs-items v-model="tab">
            <v-tab-item
              key="illustrations"
            >
              <comic-chapter-component v-if="comicbook.id"
                                       :comicbook="comicbook"
                                       :user_id="user_id"
                                       :model="'complete'"
              ></comic-chapter-component>
            </v-tab-item>
            <v-tab-item
              :key="'series'"
            >

              <comic-chapter-component
                :comicbook="comicbook"
                :user_id="user_id"
                :model="'minichap'"
              ></comic-chapter-component>

            </v-tab-item>
            <v-tab-item
              :key="'oneshot'"
              style="min-height: 500px"
            >

              <comicbook-creator-activity-component
                v-if="comicbook.id"
                :user="user"
                :comicbook="comicbook"
                :parent_id="comicbook.post_id"
              ></comicbook-creator-activity-component>
            </v-tab-item>

          </v-tabs-items>
        </template>
      </v-col>
      <v-col cols="12" md="5">
        <comicbook-comment-resume-component
          :comicbook="comicbook"
          :user="user"
          :url="`comicbook?cb_id=${comicbook.id}`"
        ></comicbook-comment-resume-component>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiExclamation,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import CreatorSeriesComponent from "@/components/creator/CreatorSeriesComponent.vue";
import ProfilePostComponent from "@/components/ProfilePostComponent.vue";
import ComicChapterComponent from "@/components/comicbook/ComicChapterComponent.vue";
import PostReplyFormComponent from "@/components/PostReplyFormComponent.vue";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ComicbookFollowToggleComponent
  from "@/components/comicbook/ComicbookFollowToggleComponent.vue";
import CommentComponent from "@/components/CommentComponent.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";
import ComicbookCommentResumeComponent
  from "@/components/comicbook/ComicbookCommentResumeComponent.vue";
import store from "@/store";
import ComicbookCreatorActivityComponent
  from "@/components/comicbook/ComicbookCreatorActivityComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import ComicbookStatisticComponent from "@/components/comicbook/ComicbookStatisticComponent.vue";
import ReadMore from "@/components/ReadMore.vue";

export default {
  components: {
    ReadMore,
    ComicbookStatisticComponent,
    PostComponent,
    ComicbookCreatorActivityComponent,
    ComicbookCommentResumeComponent,
    CommentItemComponent,
    CommentComponent,
    ComicbookFollowToggleComponent,
    FollowToggleComponent,
    PostReplyFormComponent,
    ComicChapterComponent,
    ProfilePostComponent,
    CreatorSeriesComponent,
    ProfileCreatorComponent,
    ToggleFavoriteComponent
  },
// child
  metaInfo() {
    return {
      title: `${this.comicbook.name}`,
      meta: [

        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: Drequest.__env+`comicbook/${this.comicbook.nameseo}/${this.comicbook.id}`
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: `${this.comicbook.synopsis}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${this.comicbook.cover_src}`
        },

      ]
    }
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()

    const {router} = useRouter()
    const readMoreActivated = ref(false)
    const route = router.currentRoute
    const {id} = route.params
    const comicbook = ref({})
    const loader = ref({
      detail: true,
      loading: true,
    })
    const dialog = ref({
      newpost: false,
      replypost: false,
      imageviewer: false,
      authentication: false,
      commentpost: false,
      postkola: false,
    })
    const chapters = ref([])
    const tab = ref(0)

    const user_id = localStorage.getItem('user_id')
    const user = ref(Drequest.getUser())
    let url = ''
    if (user_id) {
      url = `&user_id=${user_id}`
    }
    loader.value.detail = true
    Drequest.api(`detail.comicbook?id=${id}` + url)
      .get(response => {
        console.log(response)
        loader.value.detail = false
        comicbook.value = response.comicbook

        emit('setTitle', comicbook.value.name, `/comicbook/${comicbook.value.nameseo}/${comicbook.value.id}`)
      })

    if (store.state.sub_id) {
      url += `&subscription_id=${store.state.sub_id}`
    }

    Drequest.api(`chapter.lazyloading?dfilters=on&next=1&dsort=id desc&per_page=3&status:eq=1&comicbook_id:eq=${id}${url}`)
      //.param({next: page})
      .get(response => {
        console.log(response)
        chapters.value = response.listEntity
      })
    /*

        loader.value.loading = true
        Drequest.api(`comicbook.complete-data?cb_id=${id}${url}`)
          .param({
          })
          .get(response => {
            console.log(response)
            loader.value.loading = false
            lastcomments.value = response.lastcomments
          })
    */

    const onSubscribed = (response) => {
      console.log(response)
    }

    const slider_height = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 200
      }
      if ($vuetify.breakpoint.xs) {
        return 100
      }

      return 300
    })

    return {
      onSubscribed,

      dialog,
      loader,
      user_id,
      user,
      comicbook,
      chapters,
      slider_height,
      tab,
      readMoreActivated,

      icons: {
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
